<script>
import home from "./assets/icons/home.svg";
import homefull from "./assets/icons/home-selected.svg";

import adduser from "./assets/icons/add-user.svg";
import adduserfull from "./assets/icons/add-user-selected.svg";

import info from "./assets/icons/info.svg";
import infofull from "./assets/icons/info-selected.svg";

import you from "./assets/icons/user.svg";
import youfull from "./assets/icons/user-selected.svg";

import dotsicon from "./assets/icons/dots.svg";
import closeicon from "./assets/icons/close.svg";

import {
  validTokenAfterError,
  TokenAdminVerification,
} from "./api/httpservice";
import { Filesystem, Directory } from "@capacitor/filesystem";
import {
  Camera,
  CameraResultType,
  CameraSource,
  CameraDirection,
} from "@capacitor/camera";
import { Device } from "@capacitor/device";
import { App as CapacitorApp } from "@capacitor/app";
import { useUserStore } from "./store/userStore";
import { useEventStore } from "./store/eventStore";
import { version } from "../package.json";
import ModalItem from "@/components/elements/ModalItem.vue";
export default {
  setup() {
    const eventStore = useEventStore();
    const userStore = useUserStore();
    return { eventStore, userStore };
  },
  data() {
    return {
      connected: false,
      checked: false,
      admin: false,
      applicationVersion: version,
    };
  },
  components: {
    ModalItem,
  },
  computed: {
    async isCaptureAllowed() {
      if (
        !this.eventStore.eventIsOpen &&
        this.eventStore.eventUserRole === "participant"
      ) {
        this.emitter.emit("displayinfo", {
          title: "Événement clos",
          text: "Vous ne pouvez pas envoyer de photos car l'organisateur à clos l'événement.",
        });
        return false; // Ne pas permettre la capture si l'événement est fermé
      }

      // Vérifier si l'appareil n'est pas compatible (iPad par exemple)
      const isDeviceNotOk = await this.logDeviceInfo();
      if (isDeviceNotOk) {
        this.emitter.emit("displayinfo", {
          title: "Erreur de Device",
          text: "Votre appareil ne permet pas d'utiliser l'application correctement, contactez-nous pour plus d'informations.",
          error: true,
        });
        return false; // Ne pas permettre la capture si l'appareil est incompatible
      }

      return true; // Permettre la capture si tout est correct
    },
    isCollectionRoute() {
      return this.$route.name;
    },
    showNavbar() {
      // Access the current route's meta information
      const routeMeta = this.$route.meta;

      // Check if the "showNavbar" property is defined in the route meta
      // and is set to true
      return routeMeta && routeMeta.showNavbar === true;
    },
    showGeneralMenu() {
      // Access the current route's meta information
      const routeMeta = this.$route.meta;

      // Check if the "showNavbar" property is defined in the route meta
      // and is set to true
      return routeMeta && routeMeta.showGeneralMenu === true;
    },
  },
  created: function () {
    this.createUploadDir();
    let token;
    if (localStorage.getItem("token")) {
      token = localStorage.getItem("token");
      if (!token) {
        this.userStore.token = "";
      } else {
        this.userStore.token = token;
      }
    }

    this.emitter.on("displayinfo", this.displayInfo);
    if (token) {
      validTokenAfterError()
        .then(async (res) => {
          if (res) {
            this.connected = true;
            await this.userStore.userEventList();
            await this.userStore.getUserData();
            if (
              !this.userStore.selfieUrl &&
              this.$route.name !== "takeselfie"
            ) {
              this.emitter.emit("displayinfo", {
                title: "Vous n'avez pas de photo de profil",
                textlink: "Ajouter une photo",
                route: "take-selfie",
                text: "Vous ne pourrez pas profiter des fonctionnalités de reconnaissance de personnes.",
              });
            }
            this.adminCheck();
          } else {
            this.connected = false;
            this.userStore.disconnect();
            this.emitter.emit("connected", false);
            this.$router.push({ path: "/login" });
          }
        })
        .catch(() => {
          this.connected = false;
          this.userStore.disconnect();
          this.emitter.emit("connected", false);
          this.$router.push({ path: "/login" });
        });
    } else {
      this.connected = false;
      if (this.$route.name === "home") {
        this.$router.push({ path: "/login" });
      }
    }
    this.emitter.on("connected", this.connect);

    CapacitorApp.addListener("backButton", ({ canGoBack }) => {
      if (document.getElementById("active")) {
        this.menuClose();
      }
      if (this.eventStore.zoomCollectionOpen) {
        return;
      }
      if (!canGoBack) {
        CapacitorApp.exitApp();
      } else {
        this.$router.back();
      }
    });
  },
  methods: {
    getURL: function (imgURL) {
      switch (imgURL) {
        case "home":
          return home;
        case "homefull":
          return homefull;
        case "adduser":
          return adduser;
        case "adduserfull":
          return adduserfull;
        case "info":
          return info;
        case "infofull":
          return infofull;
        case "you":
          return you;
        case "youfull":
          return youfull;
        case "dots":
          return dotsicon;
        case "close":
          return closeicon;
        default:
          break;
      }
    },
    async createUploadDir() {
      try {
        let res = await Filesystem.mkdir({
          path: "upload",
          directory: Directory.Data,
          recursive: false,
        });
        console.log("folder ", res);
      } catch (e) {
        console.error("Unable to make directory", e);
      }
    },
    async adminCheck() {
      if (await TokenAdminVerification()) {
        this.admin = true;
        this.userStore.role = "admin";
      } else {
        this.admin = false;
      }
    },
    connect(data) {
      this.connected = data;
      this.adminCheck();
    },
    displayInfo(infodata) {
      this.userStore.ModalInfoData = {
        show: true,
        title: infodata.title ? infodata.title : "",
        text: infodata.text ? infodata.text : "",
        route: infodata.route ? infodata.route : "",
        textlink: infodata.textlink ? infodata.textlink : "",
        error: infodata.error ? infodata.error : false,
        valid: infodata.valid ? infodata.valid : false,
      };
    },
    logOut() {
      this.userStore.disconnect();
      this.$router.push({ path: "/login" });
      this.connected = false;
      this.emitter.emit("connected", false);
    },
    menuClose() {
      this.checked = false;
      document.getElementById("active").checked = false;
    },
    menuOpen(e) {
      if (e.target.checked) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },
    redirectLogin() {
      this.$router.push({ path: "/login" });
    },

    async logDeviceInfo() {
      const info = await Device.getInfo();
      return info.model.includes("iPad");
    },

    showCaptureNotAllowed() {
      if (
        !this.eventStore.eventIsOpen &&
        this.eventStore.eventUserRole === "participant"
      ) {
        this.emitter.emit("displayinfo", {
          title: "Événement clos",
          text: "Vous ne pouvez pas envoyer de photos car l'organisateur à clos l'événement.",
        });
      } else{
        this.emitter.emit("displayinfo", {
          title: "Erreur de Device",
          text: "Votre appareil ne permet pas d'utiliser l'application correctement, contacter nous pour plus d'informations.",
          error: true,
        });
      }
    },

    handleFileInput(event) {
      const file = event.target.files[0];
      if (file) {
        this.handleImage(file);
      } else {
        this.emitter.emit("displayinfo", {
          title: "Erreur",
          text: "Aucune photo sélectionnée ou capturée.",
          error: true,
        });
        this.$refs.fileInput.value = null;
      }
    },

    async handleImage(file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        console.log(reader);
        const base64data = reader.result;
        const newFileName = "upload/" + new Date().getTime() + ".jpeg";

        Filesystem.writeFile({
          path: newFileName,
          data: base64data,
          directory: Directory.Data,
        }).then(() => {
            this.$refs.fileInput.value = null;
            this.eventStore.uploadFilesInCollection();
          });
      };

      reader.readAsDataURL(file);
    },
  },
};
</script>

<template>
  <div
    id="container"
    :style="{
      backgroundColor:
        this.$route.name === 'camera'
          ? 'var(--livz-trans)'
          : 'var(--livz-blackbg)',
    }"
  >
    <ModalItem
      :show="userStore.ModalInfoData.show"
      :title="userStore.ModalInfoData.title"
      :text="userStore.ModalInfoData.text"
      :route="userStore.ModalInfoData.route"
      :textlink="userStore.ModalInfoData.textlink"
      :error="userStore.ModalInfoData.error"
      :valid="userStore.ModalInfoData.valid"
    ></ModalItem>
    <header
      class="app-header"
      :style="{
        borderBottomStyle: this.$route.name !== 'home' ? 'solid' : 'none',
      }"
      v-if="showGeneralMenu"
    >
      <RouterLink to="/"
        ><img
          class="app-logo"
          src="./assets/logo.png"
          alt="API Weaverize"
          v-if="this.$route.name !== 'home'"
      /></RouterLink>
      <div class="menu">
        <input
          type="checkbox"
          id="active"
          ref="menuInput"
          @change="menuOpen($event)"
        />
        <label for="active" class="menu-btn"
          ><img :src="checked ? getURL('close') : getURL('dots')" alt="menu"
        /></label>
        <label for="active" class="close"></label>
        <div class="wrapper" @click="menuClose">
          <ul class="links">
            <RouterLink class="app-navlink" to="/">
              <li class="home app-navlink-li-home">
                <img
                  draggable="false"
                  class="app-navlink-icon"
                  src="./assets/menu-icons/home.svg"
                  alt="Accueil"
                />Accueil
              </li>
            </RouterLink>
            <RouterLink class="app-navlink" to="/profil" v-if="connected">
              <li class="app-navlink-li-profil">
                <img
                  draggable="false"
                  class="app-navlink-icon"
                  src="./assets/menu-icons/profil.svg"
                  alt="Profil"
                />Profil
              </li>
            </RouterLink>
            <RouterLink class="app-navlink" to="/report" v-if="connected">
              <li class="app-navlink-li-report">
                <img
                  draggable="false"
                  class="app-navlink-icon"
                  src="./assets/menu-icons/report.svg"
                  alt="Signaler"
                />Signaler
              </li>
            </RouterLink>
            <RouterLink class="app-navlink" to="/cgu">
              <li class="app-navlink-li-cgu">
                <img
                  draggable="false"
                  class="app-navlink-icon"
                  src="./assets/menu-icons/terms.svg"
                  alt="CGU"
                />CGU
              </li>
            </RouterLink>
            <RouterLink class="app-navlink" to="/privacy">
              <li class="app-navlink-li-cgu">
                <img
                  draggable="false"
                  class="app-navlink-icon"
                  src="./assets/menu-icons/lock.svg"
                  alt="CGU"
                />Politique de confidentialité
              </li>
            </RouterLink>
            <a class="app-navlink" href="mailto:support@livz.app">
              <li class="app-navlink-li-contact">
                <img
                  draggable="false"
                  class="app-navlink-icon"
                  src="./assets/menu-icons/mailmenu.svg"
                  alt="Contact"
                />Contact
              </li>
            </a>
            <a class="app-navlink" href="https://livz.app/comment-ca-marche/">
              <li class="app-navlink-li-howto">
                <img
                  draggable="false"
                  class="app-navlink-icon"
                  src="./assets/menu-icons/youtube.svg"
                  alt="Comment ça marche"
                />Comment ça marche
              </li>
            </a>
            <a v-if="connected" class="app-navlink" @click="logOut">
              <li class="app-navlink-li-logout">
                <img
                  draggable="false"
                  class="app-navlink-icon"
                  src="./assets/menu-icons/logoutmenu.svg"
                  alt="Déconnexion"
                />Déconnexion
              </li>
            </a>
            <RouterLink
              v-if="admin"
              class="app-navlink"
              to="/event-administration"
            >
              <li class="app-navlink-li-admin">
                <img
                  draggable="false"
                  class="app-navlink-icon"
                  src="./assets/menu-icons/settings.svg"
                  alt="Administration des évènement"
                />Administration
              </li>
            </RouterLink>
          </ul>
          <ul>
            <p class="app-version">Version: {{ applicationVersion }}</p>
          </ul>
          <ul class="bottomLinks">
            <a class="app-navlink" href="https://livz.app/"
              ><span class="app-external-link-li-external-link website">
                <img
                  draggable="false"
                  class="app-external-link-icon"
                  src="./assets/menu-icons/websitemenu.svg"
                  alt="Site web" /></span></a
            ><a
              class="app-navlink"
              href="https://www.linkedin.com/company/livz-app/"
              ><span class="app-external-link-li-external-link linkedin">
                <img
                  draggable="false"
                  class="app-external-link-icon"
                  src="./assets/menu-icons/linkedinmenu.svg"
                  alt="LinkedIn" /></span></a
            ><a class="app-navlink" href="https://www.instagram.com/livz.app/"
              ><span class="app-external-link-li-external-link instagram">
                <img
                  draggable="false"
                  class="app-external-link-icon"
                  src="./assets/menu-icons/instagram2menu.svg"
                  alt="Instagram" /></span></a
            ><a
              class="app-navlink"
              href="https://www.facebook.com/profile.php?id=100090725937281"
              ><span class="app-external-link-li-external-link facebook">
                <img
                  draggable="false"
                  class="app-external-link-icon"
                  src="./assets/menu-icons/facebookwhitemenu.svg"
                  alt="Facebook" /></span
            ></a>
          </ul>
        </div>
      </div>
      <transition name="blur" mode="out-in">
        <div class="app-blur" v-if="checked" @click="menuClose"></div>
      </transition>
    </header>

    <div class="bottomButtons" v-if="showNavbar">
      <RouterLink
        :to="'/collection/' + eventStore.eventId"
        :class="{
          bottomButton: true,
          selected: isCollectionRoute === 'collection',
        }"
      >
        <img
          class="bottomButtonImg"
          alt="Collection"
          :src="
            isCollectionRoute === 'collection'
              ? getURL('homefull')
              : getURL('home')
          "
        />
        <div class="link-name">Home</div>
      </RouterLink>
      <RouterLink
        :to="'/qrcode/' + eventStore.eventId"
        :class="{
          bottomButton: true,
          selected: isCollectionRoute === 'qrcode',
        }"
      >
        <img
          class="bottomButtonImg"
          alt="inviter"
          :src="
            isCollectionRoute === 'qrcode'
              ? getURL('adduserfull')
              : getURL('adduser')
          "
        />
        <div class="link-name">Inviter</div>
      </RouterLink>
      <!-- Si la vérification échoue, on affiche la div normale avec l'image -->
      <div
        v-if="!isCaptureAllowed"
        @click="showCaptureNotAllowed"
        class="bottomButton"
      >
        <img alt="Collection" src="./assets/icons/plus-media.svg" />
      </div>

      <!-- Si la vérification passe, on affiche l'input stylé comme la div -->
      <label for="fileInput" class="bottomButton" v-else>
        <input
          ref="fileInput"
          id="fileInput"
          type="file"
          accept="image/*"
          capture
          @change="handleFileInput"
          class="file-input"
          style="display: none"
        />
        <img alt="Collection" src="./assets/icons/plus-media.svg" />
      </label>

      <RouterLink
        :to="'/form-event-info/' + eventStore.eventId"
        :class="{
          bottomButton: true,
          selected: isCollectionRoute === 'formeventinfo',
        }"
      >
        <img
          class="bottomButtonImg"
          alt="inviter"
          :src="
            isCollectionRoute === 'formeventinfo'
              ? getURL('infofull')
              : getURL('info')
          "
        />
        <div class="link-name">Info</div>
      </RouterLink>
      <RouterLink
        :to="'/user-page/' + userStore.userId + '/event/' + eventStore.eventId"
        :class="{
          bottomButton: true,
          selected: isCollectionRoute === 'userpage',
        }"
      >
        <img
          class="bottomButtonImg"
          alt="inviter"
          :src="
            isCollectionRoute === 'userpage' ? getURL('youfull') : getURL('you')
          "
        />
        <div class="link-name">Vous</div>
      </RouterLink>
    </div>
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" :key="$route.path" />
      </keep-alive>
    </router-view>

    <DialogsWrapper />
  </div>
</template>

<style scoped>
.bottomButtonImg {
  height: 25px;
}

.link-name {
  color: var(--livz-grey);
  font-family: "PoppinsLight", sans-serif;
  font-size: 10px;
  margin-top: 2px;
}
.bottomButtonsLine {
  height: 5px;
  transform: rotate(180deg);
  width: 100%;
  position: absolute;
  top: -5px;
}

.bottomButton {
  display: flex;
  flex-direction: column;
  width: 50%;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: var(--livz-white);
  text-decoration: none;
}

.bottomButtons {
  display: flex;
  position: absolute;
  background: var(--livz-blackbg);
  bottom: 0;
  width: 100%;
  height: 60px;
  align-items: center;
  z-index: 3;
  border-top: 1px solid var(--livz-grey);
}

.selected {
  text-decoration: none;
  color: initial;
  opacity: 1;
}

.imageMenuContainer {
  left: 0;
  top: 0;
  width: 10px;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

.imageMenu {
  height: 100%;
  transform: rotate(90deg);
  width: 100vh;
  left: 0px;
}

#container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.title {
  font-size: 1.3rem;
  color: var(--livz-white);
  line-height: 50px;
  font-weight: bold;
}

.menu {
  width: 55px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.blur-enter-active,
.blur-leave-active {
  transition: opacity 0.2s ease;
}

.blur-enter-from,
.blur-leave-to {
  opacity: 0;
}

.app-header {
  display: flex;
  justify-content: space-between;
  height: 60px;
  background-color: var(--livz-blackbg);
  border-bottom: 1px solid var(--livz-darkblue);
}

.app-logo {
  height: 40px;
  padding: 10px;
  transition: filter 0.2s ease;
}

.app-header-log {
  position: absolute;
  right: 40px;
  top: 0px;
  padding: 17px;
  font-weight: bold;
  color: var(--livz-brightblue);
  transition: color 0.2s ease;
}

.app-navlink {
  width: 100%;
  border-left: solid 5px var(--livz-bluebright);
}
.instagram img {
  transform: scale(1.5);
}

.bottomLinks li {
  border-radius: 10px;
  height: 20px;
  width: 20px;
  font-size: 1rem;
  margin: 2px;
  padding: 0.5rem;
  color: #fff;
  transition: all 0.3s ease;
  user-select: none;
}

.app-navlink-icon {
  width: 30px;
  height: 30px;
  filter: brightness(0) var(--livz-icon-invertwhite);
}

.app-external-link-icon {
  height: 25px;
  width: 25px;
}

.app-blur {
  z-index: 6;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #1f1f1f00;
  transition: all 0.3s ease-in-out;
}

.wrapper {
  z-index: 10;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 100%;
  height: 100%;
  width: 200px;
  background: var(--livz-blackbg);
  transition: all 0.6s ease-in-out;
  overflow: hidden;
}

#active:checked ~ .wrapper {
  left: calc(100% - 200px);
}

.menu-btn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12;
  right: 0px;
  top: 0px;
  height: 60px;
  width: 50px;
  text-align: center;
  line-height: 60px;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
}
.menu-btn img {
  height: 25px;
  width: 25px;
}

.close {
  z-index: 12;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition: background 0.6s;
}

.links {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
  justify-content: center;
  text-align: center;
  top: calc(45% + 50px);
  left: 50%;
  overflow: auto;
  height: 90%;
  width: 100%;
}

.bottomLinks {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: center;
  text-align: center;
  padding-top: 20px;
  top: calc(45% + 50px);
  left: 50%;
  height: 10%;
  width: 100%;
}

.bottomLinks .app-navlink {
  width: 40px;
  margin: 0 5px;
  height: 40px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e7e7e7;
  border-radius: 5px;
}

.links li {
  display: flex;
  align-items: center;
  height: 50px;
  width: calc(100% - 5px);
  font-size: 1rem;
  padding: 0.2rem 0;
  color: var(--livz-white);
  transition: all 0.3s ease;
  user-select: none;
  font-weight: bolder;
  font-family: "Poppins", sans-serif;
  text-align: left;
}

.wrapper .links li img {
  padding: 10px 10px 10px 20px;
  pointer-events: none;
}

.wrapper ul a {
  text-decoration: none;
}

input[type="checkbox"] {
  display: none;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  text-align: center;
  width: 100%;
  color: #202020;
}

.content .title {
  font-size: 40px;
  font-weight: 700;
}

.content p {
  font-size: 35px;
  font-weight: 600;
}

.wrapper ul li a {
  transition:
    opacity 0.5s,
    transform 1.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translateX(100px);
}

#active:checked ~ .wrapper ul li a {
  transition-timing-function: ease, cubic-bezier(0.1, 1.3, 0.3, 1);
  transition-delay: 0.4s;
  transform: translateX(-100px);
  opacity: 1;
}

.app-external-link-li-external-link {
  width: 25px;
  height: 25px;
}

.app-version {
  font-size: 0.8rem;
  color: var(--livz-white);
  text-align: center;
  margin-top: 10px;
}

@media (max-width: 735px) {
  .wrapper {
    width: 100%;
  }

  #active:checked ~ .wrapper {
    left: 0%;
  }

  .bottomLinks .app-navlink {
    margin: 0 10px;
    padding: 0;
  }
}
</style>
