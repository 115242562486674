import { serverUrls } from "./constants";
import { useUserStore } from "../store/userStore";

export const callAPI = async (method, path, body) => {
  let token = useUserStore().token;
  return new Promise((resolve, reject) => {
    fetch(path, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + token,
      },
      body: body !== null ? JSON.stringify(body) : null,
    })
      .then((res) => res.json())
      .then((res) => {
        if ((res.statusCode >= 200 && res.statusCode < 300) || !res.statusCode) {
          resolve(res);
        }
        reject(res);
      })
      .catch((err) => {
        console.error(err)
         validTokenAfterError().then((res)=>{
          console.log(res)
         })
         .catch((err)=>{
          console.error(err)
         })
        reject(err);
      })
  })
}

export const createMediaInCollection = (
  file,
  eventId,
  onProgress,
  tmpId
) =>
  new Promise((resolve, reject) => {
    const token = useUserStore().token;
    if (!token) {
      return false;
    }
    if (token) {
      const xhr = new XMLHttpRequest();
      if(onProgress && tmpId){
        xhr.upload.addEventListener("progress", (e) =>
        onProgress(Math.floor((e.loaded / e.total) * 100), tmpId)
        );
      }
      xhr.addEventListener("load", () =>
        resolve({ status: xhr.status, body: xhr.responseText })
      );
      xhr.addEventListener("error", () =>
        reject(new Error("File upload failed"))
      );
      xhr.addEventListener("abort", () =>
        reject(new Error("File upload aborted"))
      );
      xhr.open("POST", `${serverUrls.mediaUrl + "/media"}`, true);
      xhr.setRequestHeader("Authorization", 'Bearer ' + token);
      xhr.setRequestHeader('Accept', 'application/json');
      xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
      const formData = new FormData();
      formData.append("file", file);
      formData.append("eventId", eventId);
      xhr.send(formData);
    }
  });

  export const createUserSelfie = (file) => {
    return new Promise((resolve, reject) => {
      const token = useUserStore().token;
      if (!token) {
        return false;
      }
      const xhr = new XMLHttpRequest();
      xhr.addEventListener("load", () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve({ status: xhr.status, body: xhr.responseText });
        } else {
          reject(new Error("Request failed with status: " + xhr.status));
        }
      });
      xhr.addEventListener("error", () =>
        reject(new Error("File upload failed"))
      );
      xhr.addEventListener("abort", () =>
        reject(new Error("File upload aborted"))
      );
      xhr.open("POST", `${serverUrls.mediaUrl + "/selfie"}`, true);
      xhr.setRequestHeader("Authorization", 'Bearer ' + token);
      xhr.setRequestHeader('Accept', 'application/json');
      xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
      const formData = new FormData();
      formData.append("file", file);
      console.log(file)
      xhr.send(formData);
    });
  }

    export const createEvent = (eventData, file) =>
    new Promise((resolve, reject) => {
      const token = useUserStore().token;
      if (!token) {
        return false;
      }
      if (token) {
        const xhr = new XMLHttpRequest();
        xhr.addEventListener("load", () =>
          resolve({ status: xhr.status, body: xhr.responseText })
        );
        xhr.addEventListener("error", () =>
          reject(new Error("File upload failed"))
        );
        xhr.addEventListener("abort", () =>
          reject(new Error("File upload aborted"))
        );
        xhr.open("POST", `${serverUrls.mediaUrl + "/event"}`, true);
        xhr.setRequestHeader("Authorization", 'Bearer ' + token);
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
        const formData = new FormData();
        formData.append("name", eventData.name);
        formData.append("place", eventData.place);
        formData.append("dateStart", eventData.dateStart);
        formData.append("dateEnd", eventData.dateEnd);
        formData.append("description", eventData.description);
        formData.append("orgaLink", eventData.orgaLink);
        formData.append("file", file);
        xhr.send(formData);
      }
    });

    export const updateEvent = (eventId, eventData, file) =>
    new Promise((resolve, reject) => {
      const token = useUserStore().token;
      if (!token) {
        return false;
      }
      if (token) {
        const xhr = new XMLHttpRequest();
        xhr.addEventListener("load", () =>
          resolve({ status: xhr.status, body: xhr.responseText })
        );
        xhr.addEventListener("error", () =>
          reject(new Error("File upload failed"))
        );
        xhr.addEventListener("abort", () =>
          reject(new Error("File upload aborted"))
        );
        xhr.open("PATCH", `${serverUrls.mediaUrl + "/event"}`, true);
        xhr.setRequestHeader("Authorization", 'Bearer ' + token);
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
        const formData = new FormData();
        formData.append("eventId", eventId)
        formData.append("name", eventData.name);
        formData.append("place", eventData.place);
        formData.append("dateStart", eventData.dateStart);
        formData.append("dateEnd", eventData.dateEnd);
        formData.append("description", eventData.description);
        formData.append("orgaLink", eventData.orgaLink);
        formData.append("competitionLink", eventData.competitionLink);
        formData.append("ongoing", eventData.eventOngoing)
        formData.append("file", file);
        xhr.send(formData);
      }
    });

export const validTokenAfterError = async (notReloadData) => {
  let token = localStorage.getItem('token');
  if (!token) {
    return false;
  }
  if (token) {
    return await fetch(`${serverUrls.authUrl + "/auth/valid-token?token=" + token}`, {
      method: "GET",
      headers: {
        "Authorization": 'Bearer ' + token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if(response === false){
          useUserStore().disconnect()
          return
        }
        useUserStore().setToken(token, notReloadData)
        return response;
      })
      .catch((err) => {
        console.error(err);
        useUserStore().disconnect()
        return false;
      });
  }
};


export const TokenAdminVerification = async () => {
  let token = useUserStore().token;
  if (!token) {
    return false;
  }
  return await fetch(
    `${serverUrls.authUrl + "/admin/token-admin?token=" + token}`,
    {
      method: "GET",
      headers: {
        "Authorization": 'Bearer ' + token,
      },
    }
  )
    .then((response) => response.json())
    .then((response) => {
      if(response === false){
        return false
      }
      return response;
    })
    .catch((err) => {
      console.error(err);
      return err;
    });
};
